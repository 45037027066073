import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Slideout from "@hyjiacan/vue-slideout";
library.add(fas);
import VueSweetalert2 from "vue-sweetalert2";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@hyjiacan/vue-slideout/dist/slideout.css";
import "./app.css";
import mixin from "@/mixins";
import { authInterceptor } from "@/_helpers";

import axios from "axios";




import {apiURL} from '@/data/appdata';

console.log(apiURL);


axios.defaults.baseURL = apiURL;
axios.defaults.headers.common['content-type'] = 'application/json';




authInterceptor();

createApp(App)
  .use(store)
  .use(router)
  .use(ToastPlugin)
  .use(Slideout)
  .use(VueSweetalert2)
  .mixin(mixin)
  .component("fa-icon", FontAwesomeIcon)
  .mount("#app");
